import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Root, GlobalStyle} from './styles';
import {fetchEventsAC, updateViewportAC} from '../../shared/actions';
import {isServer} from '../../helpers';

import {MEDIUM_BREAKPOINT} from '../../shared/constants';

let debouncedFn;

class Layout extends React.Component {
  static propTypes = {
    noScroll: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    updateViewport: PropTypes.func.isRequired
  };

  checkIsMobile() {
    if (isServer()) {
      return false;
    }
    return window.innerWidth <= parseInt(MEDIUM_BREAKPOINT);
  }

  handleResize = () => {
    this.props.updateViewport({
      isMobile: this.checkIsMobile()
    });
  };

  componentDidMount() {
    const {fetchEvents} = this.props;
    fetchEvents();
    this.handleResize();
    debouncedFn = _.debounce(this.handleResize, 200);
    window.addEventListener('resize', debouncedFn);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debouncedFn);
  }

  render() {
    const {children, noScroll} = this.props;

    return (
      <Root>
        <GlobalStyle noScroll={noScroll} />
        {children}
      </Root>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchEvents: () => dispatch(fetchEventsAC()),
    updateViewport: payload => dispatch(updateViewportAC(payload))
  };
};

const mapStateToProps = state => {
  return {
    noScroll: state.viewport.isMobile && state.eventPopup.isOpen
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
