import styled, {createGlobalStyle} from 'styled-components';
import {
  FONT_SIZE,
  FONT_SIZE_MOBILE,
  MEDIUM_BREAKPOINT,
  FOOTER_HEIGHT
} from '../../shared/constants';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: ${FONT_SIZE_MOBILE}px;
  padding-bottom: ${FOOTER_HEIGHT}px;

  @media (min-width: ${MEDIUM_BREAKPOINT}) {
    padding-bottom: 0;
    font-size: ${FONT_SIZE}px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /*Fix content jumps when switching between pages https://aykevl.nl/2014/09/fix-jumping-scrollbar*/
    width: 100vw;
    overflow-x: hidden;
    overflow-y: ${props => (props.noScroll ? 'hidden' : 'auto')};
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: ${props => (props.noScroll ? 'hidden' : 'auto')};
  }
  *,
  :before,
  *:after {
    box-sizing: border-box;
  }
  
  button {
    font-size: inherit;
    font-family: inherit;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .spinner-loading {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1031;
    animation: fadeIn 0.5s infinite alternate;
  }

`;
